





















import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';

type NewsData = {
  id: string;
  urls: {
    id: number;
    title: string;
    url: string;
    target: string;
  }[];
  news_items: {
    url: string;
    description: string;
    date: string;
  }[];
  custom_fields: [
    {
      key: 'title';
      value_string: string;
    }
  ];
};

export default defineComponent({
  name: 'NewsElements',
  props: {
    data: {
      type: Object as PropType<NewsData>,
      default: {}
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const hasTitle = computed(() => {
      return props.data?.custom_fields?.some(
        (field) => field.key === 'title' && field.value_string
      );
    });

    const newsDate = (date: string): string => {
      if (!date) return '';
      const dateTemp = new Date(date);

      const day = dateTemp.getUTCDate();
      const month = dateTemp.getUTCMonth() + 1;
      const year = dateTemp.getUTCFullYear().toString().substring(2);

      const formatDateItem = (item: number) => {
        return String(item).padStart(2, '0');
      };

      return `${formatDateItem(day)}/${formatDateItem(month)}/${year}`;
    };

    return { isDesktop, hasTitle, newsDate };
  }
});
