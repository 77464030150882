










import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { getCustomFieldByKey } from '~/helpers/utils';

type TitleData = {
  description?: string;
  custom_fields: [
    {
      key: 'title';
      value_string: string;
    }
  ];
  urls?: {
    title: string;
    url: string;
    code_english: string;
    target: string;
  }[];
};

export default defineComponent({
  name: 'KETitle',
  props: {
    data: {
      type: Object as PropType<TitleData>,
      required: true,
      default: () => ({ description: '', urls: [] })
    }
  },
  setup(props) {
    const title = getCustomFieldByKey(
      props.data.custom_fields,
      'title'
    )?.value_string;

    const isBoldTitle =
      getCustomFieldByKey(props.data.custom_fields, 'bold')
        ?.custom_value_select === 'true';

    const buttonData = computed(() => {
      const url = props.data?.urls?.[0];
      if (!url) return null;
      return {
        text: url.title || '',
        url: url.url || '#',
        target: url.target || '_self'
      };
    });

    return {
      title,
      buttonData,
      isBoldTitle
    };
  }
});
